import React, { useRef } from 'react'
import { UlupinarJourneyProps } from './type'
import { useApp } from '@/core/contexts/app'
import Icon from '@/components/base/icon'
import Anchor from '@/components/base/anchor'
import { useWidth } from '@/core/hooks/useWidthResize'
import Slider from 'react-slick'
import Image from '@/components/base/image/Image'
import { Container } from '@/components/base/gridview'

const UlupinarJourney: React.FC<UlupinarJourneyProps> = ({
  title,
  description,
  items,
}) => {
  const width = useWidth()
  const app = useApp()
  const slick = useRef<Slider>(null)
  const settings = {
    dots: false,
    slidesToShow: width < 500 ? 1 : width < 1000 ? 2 : width < 1500 ? 3 : 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    arrows: false,
    infinite: true,
  }
  return (
    <div className="UlupinarSlider">
      <Container size="medium">
        <div className="UlupinarSlider-head">
          <div className="title" dangerouslySetInnerHTML={{ __html: title }} />
          {/* <Anchor className="more">
            <div
              dangerouslySetInnerHTML={{
                __html: app.settings.translations['moreinfo'],
              }}
            />
            <Icon name="arrow-right-alt" />
          </Anchor> */}
        </div>

        <div
          className="UlupinarSlider-desc"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <div className="in-slider">
          <Slider {...settings} ref={slick}>
            {items?.map((item, index) => (
              <div className="UlupinarJourney-slider" key={index}>
                <div className="UlupinarJourney-slider-item">
                  <div className="in">
                    <div
                      className="year"
                      dangerouslySetInnerHTML={{ __html: item.itemyear }}
                    />
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: item.itemdesc }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          {width < 850 && (
            <React.Fragment>
              <button
                className="arrow-prev arrow"
                onClick={() => slick.current?.slickPrev()}
              >
                <Icon name="arrow-back" />
              </button>
              <button
                className="arrow-next arrow"
                onClick={() => slick.current?.slickNext()}
              >
                <Icon name="arrow-forward" />
              </button>
            </React.Fragment>
          )}
        </div>
      </Container>
    </div>
  )
}

export default UlupinarJourney
